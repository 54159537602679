module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sciart Marketing Limited","short_name":"Sciart","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/sciart-brain-icon-full-col.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a21e413c0cc3910cc47cbcf943a273f6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2FFLQ4","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","enviroment":"www"}},"gtmAuth":"JKhgQBabjbDLd6X3Sp0e-w","gtmPreview":"env-1","dataLayerName":"dataLayer","routeChangeEventName":"route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://tags.sciart.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
